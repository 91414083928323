import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import { PageH1, PageH2 } from '../components/headers';
import Layout from '../components/layout';
import LinksDisplay from '../components/linksDisplay';
import ReleasesDisplay from '../components/release-display/releasesDisplay';
import SEO from '../components/seo';
import artistType from '../types/artistType';
import arrayHasValues from '../util/arrayHasValues';

export const query = graphql`
  query artistData($strapiId: Int!) {
    strapiArtists(strapiId: { eq: $strapiId }) {
      Description
      Name
      CoverImage {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      Links {
        Link
        Type
      }
      releases {
        Title
        id
        Cover {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        ReleaseDate(formatString: "yyyy")
        order: ReleaseDate(formatString: "x")
        FullReleaseDate: ReleaseDate
      }
    }
  }
`;

const ArtistTemplate = ({ data }) => {
  const artist = data.strapiArtists;

  const releases = artist.releases
    .filter((r) => new Date(r.FullReleaseDate) <= new Date())
    .sort((a, b) => parseInt(b.order) - parseInt(a.order));

  const upcomingReleases = artist.releases
    .filter((r) => new Date(r.FullReleaseDate) > new Date())
    .sort((a, b) => parseInt(b.order) - parseInt(a.order));

  return (
    <Layout>
      <SEO title={artist.Name} />
      <div className="text-xl">
        <div className="grid grid-cols-2">
          <div className="m-2 lg:m-4 xl:m-8">
            <GatsbyImage
              className="object-cover w-full pointer-events-none select-none"
              image={
                artist.CoverImage.localFile.childImageSharp.gatsbyImageData
              }
              alt={`${artist.Name} cover`}
            />
          </div>
          <div className="p-8">
            <PageH1>{artist.Name}</PageH1>
            <div className="text-gray-300 mt-5">{artist.Description}</div>
          </div>
        </div>
        <div className="p-8">
          {arrayHasValues(upcomingReleases) && (
            <>
              <PageH2>Upcoming Releases</PageH2>
              <div className="">
                <ReleasesDisplay releases={upcomingReleases} />
              </div>
            </>
          )}
          {arrayHasValues(releases) && (
            <>
              <PageH2>Releases</PageH2>
              <div className="">
                <ReleasesDisplay releases={releases} />
              </div>
            </>
          )}
          {arrayHasValues(artist.Links) && (
            <>
              <PageH2 className="text-2xl mt-8 mb-5 text-indigo-400">
                Follow {artist.Name}
              </PageH2>
              <LinksDisplay links={artist.Links} />
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

ArtistTemplate.propTypes = {
  data: PropTypes.shape({
    strapiArtists: artistType,
  }).isRequired,
};

export default ArtistTemplate;
